import ReactLoading from "react-loading";
import { Modal } from "antd";
import { EyeFilled, HistoryOutlined, StepForwardOutlined, StepBackwardOutlined, ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import logoSol from "../../assets/img/sol.svg";
import iconWin from "../../assets/img/icon-win.svg";
import iconWinrate from "../../assets/img/icon-winrate.svg";
import iconLucky from "../../assets/img/icon-lucky.svg";
import iconPlayed from "../../assets/img/icon-played.svg";
import iconStar from "../../assets/img/icon-star.svg";
import defaultUser from "../../assets/img/default_user.png";

import { truncateString, abbreviateNumber } from "../../ultils";

import { LAMPORTS_PER_SOL, SystemProgram } from "@solana/web3.js";
import { useWallet, useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

import { AnchorProvider, setProvider } from "@coral-xyz/anchor";

import { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import Countdown, { zeroPad } from "react-countdown";

import Slider from "react-slick";

import { depositCurrent, depositNext, valuePerEntry, mindept, maxdept } from "../../web3/main";
import * as helpers from "../../web3/helpers";

import toast from "react-hot-toast";

import Cookies from 'js-cookie';
import dayjs from "dayjs";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


ChartJS.register(ArcElement, Tooltip);

const getIsMobile = () => window.innerWidth <= 991;

const Game = () => {

  const navigate = useNavigate();
  const sliderRef = useRef();
  
  const { connection } = useConnection();
  const wallet = useAnchorWallet();
  const provider = new AnchorProvider(connection, wallet, {});
  setProvider(provider);

  const { publicKey } = useWallet();
  const { sendTransaction } = useWallet();


  const program = helpers.getProgram();

  const [solBalance, setSolBalance] = useState(0);
  const [solUsd, setSolUsd] = useState(0);

  const [isMobile, setIsMobile] = useState(getIsMobile());
  const [isShowDeposit, setIsShowDeposit] = useState(isMobile ? false : true);

  const [amountInput, setAmountInput] = useState(process.env.REACT_APP_MINDEPT);
  const [roundsInput, setRoundsInput] = useState(1);
  const [totalEntries, setTotalEntries] = useState(amountInput*roundsInput);

  const [chartData, setChartData] = useState();
  const [chartOptions, setChartOptions] = useState();
  
  const [players, setPlayers] = useState();
  const [player, setPlayer] = useState();

  const [rotate, setRotate] = useState(0);
  const [isDrawing, setIsDrawing] = useState(false);  
  const [isDrawn, setIsDrawn] = useState(false);  
  const [isGenerating, setIsGenerating] = useState(false);  
  
  const [winner, setWinner] = useState();  
  const [drawAt, setDrawAt] = useState(dayjs()); 
  const [isNextRound, setIsNextRound] = useState(false);
  const [enabledAddFun, setEnabledAddFun] = useState(false);
  const [nextRoundTimeRemain, setNextRoundTimeRemain] = useState(20);

  const [nextRoundUserDept, setNextRoundUserDept] = useState();
  

  const [selectedBtnId, setSelectedBtnId] = useState();
  const [btnLoading, setBtnLoading] = useState(false);

  const addSpinner = (btnId) => {
    setBtnLoading(true);
    setSelectedBtnId(btnId);
  };
  const removeSpinner = (btnId) => {
    setBtnLoading(false);
    setSelectedBtnId(null);
  };

  const [currentRoundInfo, setCurrentRoundInfo] = useState({
    claimed: false,
    deposits: [],
    drawnAt: 0,
    index: 0,
    startAt: 0,
    status: `open`,
    totalAmount: 0,
    winner: '',
    playerInfo: {}
  });

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    csGetSOLBalance();
    getSolUsd();
    csGetCurrentRound();
    csGetNextRoundUser();
  }, [publicKey]);

  useEffect(() => {
    // get current round event deposit
    const subscriptionDepositEvent = program.addEventListener("DepositEvent", (event) => {
      if(isDrawing || isGenerating){
        return
      }

      getRoundEvent(event);

      // console.log(`DepositEvent`, event)

    });
    const subscriptionDrawWinnerEvent = program.addEventListener("DrawWinnerEvent", (event) => {
      // console.log(`DrawWinnerEvent`, event)
      const round = {
        index: parseInt(event.roundNumber.toString()),
        winner: event.winner.toBase58()
      }
      draw(round);
    });

  }, [currentRoundInfo]);

  const draw = async (round) => {
    setIsGenerating(false);
    let nextRoundCount = 20;
    if(round.winner == `11111111111111111111111111111111`){
      nextRoundCount = 10;
    }

    // console.log('drawing...')
    // console.log(`roundEvent`, round);
    
    setEnabledAddFun(false);
    // await csGetRound(round.index);
    setTimeout(
      () => {
        // console.log('get new round')
        csGetCurrentRound();
        setRotate(0);
        setIsDrawn(false);
        setWinner('');
        sliderRef?.current?.slickGoTo(0)
        
      },
      nextRoundCount * 1000
    );
        
    if(players && round.winner != `11111111111111111111111111111111`){
      
      setIsDrawing(true);
      // setup winner
      let degMax = 0;
      let winnerObj = players.find(x => x.wallet === round.winner);
      let winnerIndex = players.indexOf(winnerObj);
      players.slice(0, winnerIndex+1).map((item) => {
        let currentDeg = 360*item.winchance/100;
        degMax += currentDeg;
      });
      let degMin = degMax - 360*players[winnerIndex]?.winchance/100;
      setTimeout(() => {
        const rotateDeg = Math.random() * (degMax - degMin) + degMin;
        const rotateValue = (20*360 - rotateDeg);
        setRotate(rotateValue);
      }, 1000);

      setTimeout(
        () => {
          setIsDrawing(false);
          setIsDrawn(true);
          setWinner(round.winner);
          sliderRef.current.slickGoTo(winnerIndex);
          setIsNextRound(true);
        },
        10000
      );
    }else{
      setIsDrawing(false);
      setIsDrawn(true);
      setIsNextRound(true);
    }

    
    let nextTimer = setInterval(function(){
        if(nextRoundCount <= 0){
          clearInterval(nextTimer);
        }
        setNextRoundTimeRemain(nextRoundCount);
        nextRoundCount -= 1;
    }, 1000);
  }

  const csGetNextRoundUser = async () => {
    const nextRoundInfo = await helpers.getNextRound(publicKey);
    // console.log(`nextRoundInfo`, nextRoundInfo)
    setNextRoundUserDept(nextRoundInfo.currentPlayer)
  }

  const getRoundEvent = async (event) => {
    // console.log(`event`, event)
    // const roundNumber = event.roundNumber;
    csGetCurrentRound();
  }

  const csGetCurrentRound = async () => {
    const round = await helpers.getCurrentRound(publicKey);
    setDrawAt(round.cutOffTime * 1000);
    setIsGenerating(false);
    setData(round);
  }

  // const csGetRound = async (roundIndex) => {
  //   const roundPublicKey = await helpers.getRoundPublicKey(roundIndex);
  //   const round = await helpers.getRound(roundIndex, roundPublicKey, publicKey);
  //   setData(round);
  // }

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const handleCancelUserModal = () => {
    setIsUserModalOpen(false);
  };
  const handleOpenUserModal = async (userInfo) => {
    // setPlayer(userInfo);

    // get api user
    const apiPlayer = await helpers.apiPlayer(userInfo.wallet);
    setPlayer(apiPlayer);

    // console.log(`player`, {apiPlayer, ...userInfo})

    setIsUserModalOpen(true);
  };


  const handleRoundOldest = () => {
    let roundIndex = 0;
    if(currentRoundInfo && currentRoundInfo.index > 1){
      roundIndex = currentRoundInfo.index - 1;
    }
    navigate(`/history/${roundIndex}`, { replace: false });
  }

  const handleDepositCurrent = async () => {
    await csGetSOLBalance();
    if(amountInput > solBalance){ 
      toast.error(`Insufficient balance.`); return;
    }
    if(amountInput < mindept || amountInput > maxdept){
      toast.error(`Amount must be between ${mindept} SOL and ${maxdept} SOL.`); return;
    }
    const refFrom = Cookies.get('ref_from');
    const btnID = "deposit";
    addSpinner(btnID);


    let deposit;
    if(roundsInput > 1){
      deposit = await depositNext(roundsInput, amountInput * roundsInput, refFrom, connection, wallet, sendTransaction);
    }else{
      deposit = await depositCurrent(amountInput, refFrom, connection, wallet, sendTransaction);
    }
    
    if (deposit.status == "success") {
      removeSpinner(btnID);
      isMobile && setIsShowDeposit(false);
      toast.success(deposit.message);
    } else {
      removeSpinner(btnID);
      toast.error(deposit.message);
    }
  };

  const setData = async (round) => {
    console.log(`round`, round)
    let depositer = [];
    if(round){
      if(round.deposits.length > 0){
        round.deposits.map((item, index) => {
          depositer.push({
            wallet: item.depositor,
            winchance: (item.amount/round.totalAmount * 100).toFixed(1),
            amount: item.amount,
            avatar: helpers.getAvatar(item.depositor),
            points: item.totalEntry * process.env.REACT_APP_POINT_VALUE,
            color: helpers.getColor(index),
            is_won: false,
            played: 0,
            won: 0,
            biggest_win: 0,
            luckiest_win: 0
          })
        });
      }
    }
    console.log(`depositer`, depositer)
    setPlayers(depositer);

    setCurrentRoundInfo(round);

    

    const chartLabels = [];
    const chartNumber = [];
    const chartBg = [];
    depositer.map((item) => {
      chartLabels.push(truncateString(item.wallet, 4, 4));
      chartNumber.push(item.amount);
      chartBg.push(`#${item.color}`);
    });

    setChartData({
      labels: chartLabels,
      datasets: [
        {
          label: "",
          data: chartNumber,
          backgroundColor: chartBg,
          borderWidth: 0,
          hoverOffset: 0,
          cutout: '75%',
          rotation: 0,
          animations: false,
        }
      ],
    });

    setChartOptions(
      {
        plugins: {
          tooltip: {
            enabled: false,
            external: function(context) {
              //document.querySelectorAll(".player-item").forEach(el => el.classList.remove("hover"));
              //const tooltipModel = context.tooltip;
              // console.log(`tooltipModel`, tooltipModel);
              //const el = document.querySelectorAll(`.player-item[data-index='${tooltipModel.dataPoints[0].dataIndex}']`);
              // console.log(`el`, el[0])
             // el[0].classList.add("hover");
            }
          }
        },
        onClick: (e) => {
          // console.log(e.chart.tooltip.dataPoints[0].dataIndex);
        }
      }
    )
  };

  const ref = useRef(null);

  const handleMaxBtn = async () => {
    await csGetSOLBalance();
    if(maxdept <= solBalance){
      setAmountInput(maxdept);
    }else{
      setAmountInput(solBalance);
    }
  };

  const csGetSOLBalance = async () => {
    if (!publicKey) return;
    let balance = await connection.getBalance(publicKey);
    setSolBalance(balance / LAMPORTS_PER_SOL);
  };

  const getSolUsd = async () => {
    fetch(
      "https://api.binance.com/api/v3/ticker/price?symbol=SOLUSDT"
    )
      .then((response) => response.json())
      .then((jsonData) => {
        setSolUsd(jsonData.price);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsGenerating(true);
      return <>
          <button className="flex items-center justify-center gap-2">
            <ReactLoading type="spin" height={26} width={26} />
          </button>
        </>;
    } else {
      setIsGenerating(false);
      return (
        <button className="flex items-center justify-center gap-2">
            {zeroPad(minutes)}:{zeroPad(seconds)}
        </button>
      );
    }
  };  

  const slickSettings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false
  };


  if(currentRoundInfo){
    // let startAt = dayjs(currentRoundInfo.startAt * 1000);
    // let drawAt = startAt.add(301, `second`);
    // console.log(`startAt`, startAt)
    // console.log(`drawAt`, drawAt.unix())

    // const date = new Date(
    //   parseInt(drawAt.format('YYYY')), 
    //   parseInt(drawAt.format('M')) - 1, 
    //   parseInt(drawAt.format('D')), 
    //   parseInt(drawAt.format('H')), 
    //   parseInt(drawAt.format('m')), 
    //   parseInt(drawAt.format('s')),
    // );
    // console.log(`schedule date`, date)
  }

  return (
    <div className="container-fluid mx-auto py-0 px-2 game">
      <div className="flex flex-col lg:grid grid-cols-12 grid-rows-6 lg:gap-2 h-full">

        <div className="grid-box  round-info col-span-3 row-span-3 row-start-1 col-start-1">
          <div className="spin-box h-full">
            <div className="spin-box-head flex justify-between lg:mb-3 items-center hidden lg:flex">
              <NavLink to="/history" className="flex items-center gap-2 px-3 text-sm py-[8px] rounded-[12px] border-[#735893] border uppercase">
                <HistoryOutlined /> History
              </NavLink>
              <div className="slick-control flex gap-1">
                <button className="button is-outline"
                  onClick={() => { handleRoundOldest() }}
                ><ArrowLeftOutlined /></button>
                <button className="button is-outline disabled opacity-50" ><ArrowRightOutlined /></button>
                <button className="button is-outline disabled opacity-50" ><StepForwardOutlined /></button>
              </div>
            </div>
            {
              (drawAt && drawAt > 0) ?
              <div className="countdown">
                <Countdown
                  key={drawAt.toString()}
                  date={drawAt}
                  renderer={renderer}
                ></Countdown>
              </div>
              :
              <div className="countdown">
                <button className="flex items-center justify-center gap-2">
                02:00
                </button>
              </div>
            }
            {/* {
              currentRoundInfo && currentRoundInfo.cutOffTime == 0 && 
              
            } */}
            
            
            <div className="mt-2">
              <div className="grid grid-cols-3 lg:grid-cols-2 text-[#ffffff80] ">
                <div className="block text-sm p-2 hidden lg:block text-center">
                  <p className="text-xs mb-1">Round</p>
                  <div className="flex items-center justify-center gap-2 text-xl font-bold lg:mb-1 text-[#ffffff]">
                    {currentRoundInfo.config ? currentRoundInfo.config.currentRound : `-`}
                  </div>
                </div>

                <div className="block text-sm hidden lg:block p-2 text-center">
                  <p className="text-xs mb-1">Watching</p>
                  <div className="flex items-center justify-center gap-2 text-xl font-bold lg:mb-1 text-[#FDDD34]">
                    <EyeFilled /> -
                  </div>
                </div>

                <div className="block text-sm p-2 hidden lg:block text-center border-t-[1px] border-[#ffffff1f]">
                  <p className="text-xs mb-1">Players</p>
                  <div className="flex items-center justify-center text-xl font-bold lg:mb-1">
                    <span className="text-[#ffffff]">{players ? players.length : `-`}</span>/10K
                  </div>
                </div>

                <div className="block text-sm p-2 text-center lg:border-t-[1px] lg:border-[#ffffff1f]">
                  <p className="text-xs mb-1">Prize Pool</p>
                  <div className="flex items-center justify-center gap-2 text-xl font-bold lg:mb-1 text-[#ffffff]">
                    <img  className="w-[24px] h-[24px]" src={logoSol} />
                    {currentRoundInfo.totalAmount.toLocaleString('en-US')}
                  </div>
                </div>

                <div className="block text-sm p-2 lg:pt-1 text-center">
                  <p className="text-xs mb-1">Your Win Chance</p>
                  <div className="flex items-center justify-center gap-2 text-xl font-bold lg:mb-1">
                    { currentRoundInfo.playerInfo.winChance ? currentRoundInfo.playerInfo.winChance.toFixed(2) : `0` }%
                  </div>
                </div>

                <div className="block text-sm p-2 lg:pt-1 text-center">
                <p className="text-xs mb-1">Your entries</p>
                  <div className="flex items-center justify-center gap-2 text-xl font-bold lg:mb-1">
                    <img  className="w-[24px] h-[24px]" src={logoSol} />
                    { currentRoundInfo.playerInfo.amount }
                  </div>
                </div>

                <div className="block text-sm p-2 hidden lg:block text-center border-t-[1px] border-[#ffffff1f]">
                  <p className="text-xs mb-1">Your Future Rounds</p>
                  <div className="flex items-center justify-center text-xl font-bold lg:mb-1">
                    { nextRoundUserDept && nextRoundUserDept.remainingRound ? nextRoundUserDept.remainingRound.toLocaleString('en-US') : '-' }
                  </div>
                </div>

                <div className="block text-sm p-2 hidden lg:block text-center border-t-[1px] border-[#ffffff1f]">
                  <p className="text-xs mb-1">Your Future Entries</p>
                  <div className="flex items-center justify-center gap-2 text-xl font-bold lg:mb-1 text-[#ffffff]">
                    <img  className="w-[24px] h-[24px]" src={logoSol} />
                    { nextRoundUserDept && nextRoundUserDept.totalAmount ? (nextRoundUserDept.totalAmount / LAMPORTS_PER_SOL).toLocaleString('en-US') : '-' }
                  </div>
                </div>
              </div>
            </div>
            {
              publicKey ? 
              <button
                onClick={() => setIsShowDeposit(true)}
                // className={`lg:!hidden button bg-primary-gradient w-full has-icon mt-2 ${currentRoundInfo?.cutOffTime < dayjs() && `opacity-50`}`}
                className={`lg:!hidden button bg-primary-gradient w-full has-icon mt-2`}
                // disabled={currentRoundInfo?.cutOffTime < dayjs() && `disabled`}
                >Enter now</button>
                :
                <div className="lg:!hidden connect-btn-condition mt-2"><WalletMultiButton/></div>
                
            }
          </div>
        </div>
        {
          isShowDeposit &&
          <div className="box-deposit-mask"></div>
        }
        
        <div className={`grid-box box-deposit col-span-3 row-span-3 row-start-4 col-start-1 ${isShowDeposit && 'show'}`}>
          <div className="spin-box h-full relative">
            
                <div>
                  <div className="py-3 pt-0 flex justify-between items-center">
                    <h4 className="font-medium">SOL in Wallet</h4>
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-[#91abc9]">${(solBalance*solUsd).toLocaleString("en-US")}</span>
                      <div className="flex items-center gap-2 font-bold text-[20px]">
                        {solBalance.toLocaleString("en-US")}  
                        <img  className="w-[20px] h-[20px]" src={logoSol} />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between items-center border-t-[1px] border-[#ffffff1f]">
                    <div className="block text-sm py-2 lg:hidden">
                      <p className="text-xs mb-1">Your Future Rounds</p>
                      <div className="flex items-center text-xl font-bold lg:mb-1">
                        { nextRoundUserDept && nextRoundUserDept.remainingRound ? nextRoundUserDept.remainingRound.toLocaleString('en-US') : '-' }
                      </div>
                    </div>

                    <div className="block text-sm py-2 lg:hidden text-center">
                      <p className="text-xs mb-1">Your Future Entries</p>
                      <div className="flex items-center justify-center gap-2 text-xl font-bold lg:mb-1 text-[#ffffff]">
                        <img  className="w-[24px] h-[24px]" src={logoSol} />
                        { nextRoundUserDept && nextRoundUserDept.totalAmount ? (nextRoundUserDept.totalAmount / LAMPORTS_PER_SOL).toLocaleString('en-US') : '-' }
                      </div>
                    </div>
                  </div>

                  <div className="py-3 border-t-[1px] border-[#ffffff1f]">
                    <h4 className="font-medium">SOL entry per round</h4>
                    <div className="relative mt-2">
                      <input
                        type="number"
                        placeholder=""
                        className="w-full py-3 px-3 rounded-[12px] bg-[#271B40]"
                        value={amountInput}
                        onChange={(e) => setAmountInput(e?.target?.value)}
                      />
                    </div>
                    {
                      (publicKey && amountInput > solBalance-0.005) &&
                        <span className="error">Insufficient balance</span>
                    }
                    <div className="handleAmount flex justify-between gap-2 mt-4">
                      <button className="flex-1 flex items-center justify-center gap-1 px-3 text-sm py-1.5 rounded-[12px] bg-[#432D5C]"
                        onClick={() => setAmountInput(0.1)}
                      >
                        0.1 <img className="w-[16px] h-[16px]" src={logoSol} />
                      </button>
                      <button className="flex-1 flex items-center justify-center gap-1 px-3 text-sm py-1.5 rounded-[12px] bg-[#432D5C]"
                        onClick={() => setAmountInput(1)}>
                        1 <img className="w-[16px] h-[16px]" src={logoSol} />
                      </button>
                      <button className="flex-1 flex items-center justify-center gap-1 px-3 text-sm py-1.5 rounded-[12px] bg-[#432D5C]"
                      onClick={() => setAmountInput(10)}>
                        10 <img className="w-[16px] h-[16px]" src={logoSol} />
                      </button>
                      <button className="flex-1 flex items-center justify-center gap-1 px-3 text-sm py-1.5 rounded-[12px] bg-[#432D5C]"
                      onClick={handleMaxBtn}>
                        MAX
                      </button>
                    </div>
                  </div>
                  <div className="py-3 border-t-[1px] border-[#ffffff1f]">
                    <h4 className="font-medium">Number of Rounds</h4>
                    <div className="relative mt-2">
                      <input
                        type="number"
                        step={1}
                        className="w-full py-3 px-3 rounded-[12px] bg-[#271B40]"
                        value={roundsInput}
                        onChange={(e) => setRoundsInput(e?.target?.value)}
                      />
                    </div>
                  </div>
                  <div className="py-3 border-t-[1px] border-[#ffffff1f]">
                    <div className="flex justify-between items-center">
                      <h4 className="font-medium">Total Entry</h4>
                      <div className="flex items-center gap-2">
                        <span className="text-xs text-[#91abc9]">${(amountInput*roundsInput*solUsd).toLocaleString("en-US")}</span>
                        <div className="flex items-center gap-2 font-bold text-[20px]">
                        {(amountInput*roundsInput).toLocaleString("en-US")} 
                          <img  className="w-[20px] h-[20px]" src={logoSol} />
                        </div>
                      </div>
                    </div>
                    {
                      (publicKey &&  amountInput*roundsInput > solBalance-0.005) &&
                        <span className="error text-right">Insufficient balance</span>
                    }
                  </div>

                  {
                    publicKey && !isDrawing && !isGenerating &&
                      <>
                        <button
                            disabled={
                              btnLoading && selectedBtnId === "deposit"
                                ? true
                                : false
                            }
                            onClick={() => { handleDepositCurrent() }} 
                            className="button bg-primary-gradient w-full mt-1 has-icon">
                              <span>PLace your entries!</span>
                              {btnLoading && selectedBtnId === "deposit" && (
                                <ReactLoading type="spin" height={18} width={18} />
                              )}
                        </button>
                        <button
                          onClick={() => { setIsShowDeposit(false) }} 
                          className="button w-full mt-2 block lg:hidden">
                            <span>Cancel</span>
                        </button>
                      </>
                  }
                  {
                    publicKey && (isDrawing || isGenerating ) &&
                      <>
                        <button
                            disabled={true}
                            className="button bg-primary-gradient w-full mt-1 has-icon opacity-50">
                              <span>PLace your entries!</span>
                        </button>
                        <button
                          onClick={() => { setIsShowDeposit(false) }} 
                          className="button w-full mt-2 block lg:hidden">
                            <span>Cancel</span>
                        </button>
                      </>
                  }
                  
                  {
                    !publicKey &&
                      <div className="connect-btn-condition">
                        <WalletMultiButton className="w-full" />
                      </div>
                  }
                  
                </div>
            
          </div>
        </div>

        <div className="grid-box box-players col-span-9 row-span-2 col-start-4 row-start-5">
          <div className="spin-box h-full relative">
            <div className="spin-box-head flex justify-between mb-2 items-center">
              <h3 className="font-bold text-lg">Current Round - {players?.length} Players</h3>
              <div className="slick-control flex gap-1">
                <button className="button is-outline"
                  onClick={() => sliderRef.current.slickPrev()}
                ><ArrowLeftOutlined /></button>
                <button className="button is-outline"
                  onClick={() => sliderRef.current.slickNext()}
                ><ArrowRightOutlined /></button>
                <button className="button is-outline"
                  onClick={() => sliderRef.current.slickGoTo(players.length)}
                ><StepForwardOutlined /></button>
              </div>
            </div>
            <Slider ref={sliderRef} className="players-list" {...slickSettings}>
              {
                players &&
                  players.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => handleOpenUserModal(item)}
                        data-index={index}
                        className={`player-item flex flex-col items-center justify-center gap-3 ${item.wallet == winner && 'win'}`}
                      >
                        <div className={`color color-${item.color}`}></div>
                        <img className="w-[46px] h-[46px] m-auto mb-2 rounded-full bg-[#001529]" src={item.avatar}/>
                        <div className="wallet mb-2">
                          <div className="font-bold">
                            {truncateString(item.wallet, 4, 4)}
                          </div>
                          <span className="text-xs text-[#ffffff80]">{abbreviateNumber(item.points)} PTS</span>
                        </div>
                        <div className="amount text-right">
                          <div className="winchance mb-2 font-bold">{item.winchance}%</div>
                          <div className="flex items-center justify-center gap-1">
                            {item.amount}
                            <img  className="w-[18px] h-[18px]" src={logoSol} />
                          </div>
                        </div>
                      </div>
                    )
                  })
              }
            </Slider>
            <div id="chartjs-tooltip"></div>
          </div>
        </div>
        <div className="grid-box box-chart col-span-9 row-span-4 col-start-4 row-start-1 order-first lg:order-none">
          <div className="spin-box-head flex justify-between lg:mb-3 items-center lg:hidden relative z-[99]">
            <NavLink to="/history" className="flex items-center gap-2 px-3 text-sm py-[8px] rounded-[12px] border-[#735893] border uppercase">
              <HistoryOutlined /> History
            </NavLink>
            <div className="slick-control flex gap-1">
              <button className="button is-outline"
                onClick={() => { handleRoundOldest() }}
              ><ArrowLeftOutlined /></button>
              <button className="button is-outline disabled opacity-50" ><ArrowRightOutlined /></button>
              <button className="button is-outline disabled opacity-50" ><StepForwardOutlined /></button>
            </div>
          </div>
          <div className="spin-box spin-box-main h-full relative">
            <div className="spinner-wrp">
              <div className="spinner">
                <div className="spinner-inner">
                  {
                    chartData && 
                      <div className="spinwrp">
                        <div className="spincontent-wrp"></div>
                        <div className="spincontent">

                          {
                            !isDrawing && !isDrawn &&
                            <div className="inner deposit">
                              <div className="flex items-center justify-center text-[32px] lg:text-[48px] font-bold gap-1 lg:gap-2">
                                <img className="w-[32px] lg:w-[40px] h-[32px] lg:h-[40px]" src={logoSol} />
                                <span>{currentRoundInfo?.totalAmount}</span>
                              </div>
                              {
                                isGenerating ?
                                <div className="text-center font-bold text-[18px] lg:text-[24px] mb-1 text-primary-gradient blink_me">
                                  Generating Randomness...
                                </div>
                                :
                                <div className="flex items-center justify-center text-[16px] lg:text-[18px] gap-1 lg:gap-2">
                                  <span>${(currentRoundInfo?.totalAmount * solUsd).toLocaleString('en-US')}</span>
                                </div>
                              }
                            </div>
                          }
                          { 
                            isDrawing && 
                            <div className="inner drawing">
                              <div className="flex items-center justify-center text-[32px] lg:text-[48px] font-bold gap-1 lg:gap-2">
                                <img className="w-[32px] lg:w-[40px] h-[32px] lg:h-[40px]" src={logoSol} />
                                <span>{(currentRoundInfo?.totalAmount).toLocaleString('en-US')}</span>
                              </div>
                              <div className="text-center font-bold text-[18px] lg:text-[24px] mb-1 text-primary-gradient blink_me">Drawing Winner...</div>
                            </div>
                          }
                          { 
                            !isDrawing && isDrawn && winner == `11111111111111111111111111111111` &&
                            <div className="inner cancelled">
                              <div className="flex items-center justify-center text-[32px] lg:text-[48px] font-bold gap-1 lg:gap-2">
                                <img className="w-[32px] lg:w-[40px] h-[32px] lg:h-[40px]" src={logoSol} />
                                <span>{(currentRoundInfo?.totalAmount).toLocaleString('en-US')}</span>
                              </div>
                              <div className="text-center font-bold text-[18px] lg:text-[24px] mb-1 text-primary-gradient">Not Enough Players</div>
                              { isNextRound &&
                                <div className="text-center font-bold">Next round: {nextRoundTimeRemain}s</div>
                              }
                            </div>
                          }
                          { 
                            !isDrawing && isDrawn && winner != `11111111111111111111111111111111` &&
                            <div className="inner winner text-center ">
                              <div className="win-wrp">
                                <img src={winner ? helpers.getAvatar(winner) : defaultUser} />
                              </div>
                              <div className="font-bold text-primary-gradient text-[18px] lg:text-[24px] mb-1">{winner && truncateString(winner, 4, 4)}</div>
                              <div className="flex items-center justify-center text-[24px] lg:text-[48px] font-bold gap-1 lg:gap-2 leading-6">
                                <img className="w-[24px] lg:w-[40px] h-[24px] lg:h-[40px]" src={logoSol} />
                                <span>{(currentRoundInfo?.totalAmount).toLocaleString('en-US')}</span>
                              </div>
                              {/* <div className="flex items-center justify-center gap-2">
                                <div className="flex items-center gap-1">
                                  <span className="text-xs lg:text-base">1.5x WIN</span>
                                </div>
                              </div> */}
                              { isNextRound &&
                                <div className="text-center font-bold">Next round: {nextRoundTimeRemain}s</div>
                              }
                            </div>
                          }
                        </div>
                        <div className={`spinchart ${rotate > 0 && `spin`}`} style={{
                          transform: `rotate(${rotate}deg)`
                        }}>
                          <Doughnut data={chartData} options={chartOptions} />
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
      
      <Modal
        forceRender
        open={isUserModalOpen}
        onCancel={handleCancelUserModal}
        footer={false}
        className="modal-user max-w-[560px]"
        style={{
          backgroundImage: `url(${player ? helpers.getAvatar(player.wallet) : ''})`
        }}
      >
        <div className="text-white">
          <div className="profile text-center mt-5">
            <img className="w-[70px] h-[70px] m-auto rounded-full bg-[#001529]" src={ player ? helpers.getAvatar(player.wallet) : defaultUser}/>
            <div className="font-bold  text-[24px] mb-1 mt-3">{player && truncateString(player?.wallet, 4, 4)}</div>
          </div>
          <div className="user-past-rounds mt-5">
            <div className="flex items-center justify-between mb-5">
              <h3 className="font-bold mb-2">Player Statistic</h3>
              <NavLink target="_blank" to={`https://explorer.solana.com/address/${player?.wallet}?cluster=${process.env.REACT_APP_NETWORK}`} className="flex items-center gap-2 text-[#fff] font-bold" >
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_62_4918" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
                <rect width="22" height="22" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_62_4918)">
                <path d="M4.95005 18.7C4.4963 18.7 4.10786 18.5385 3.78474 18.2154C3.46161 17.8922 3.30005 17.5038 3.30005 17.05V4.95005C3.30005 4.4963 3.46161 4.10786 3.78474 3.78474C4.10786 3.46161 4.4963 3.30005 4.95005 3.30005H11V4.95005H4.95005V17.05H17.05V11H18.7V17.05C18.7 17.5038 18.5385 17.8922 18.2154 18.2154C17.8922 18.5385 17.5038 18.7 17.05 18.7H4.95005ZM8.8688 14.3L7.70005 13.1313L15.8813 4.95005H13.2V3.30005H18.7V8.80005H17.05V6.1188L8.8688 14.3Z" fill="white"/>
                </g>
                </svg>
                WALLET</NavLink>
            </div>

            <div className="flex flex-wrap gap-2 lg:flex-nowrap justify-between mt-2">
              <div className="p-3 bg-[#271B40] rounded-[12px] w-[48%] lg:w-[25%]">
                <span className="text-xs text-[#91abc9]">Wins</span>
                <h4 className="font-bold text-lg flex gap-1 items-center">
                  <img className="w-[20px]" src={iconWin} />
                  {player?.total_win}
                </h4>
              </div>
              <div className="p-3 bg-[#271B40] rounded-[12px] w-[48%] lg:w-[25%]">
                <span className="text-xs text-[#91abc9]">Total Played</span>
                <h4 className="font-bold text-lg flex gap-1 items-center">
                  <img className="w-[20px]" src={iconPlayed} />
                  {player?.total_played}
                </h4>
              </div>
              
              <div className="p-3 bg-[#271B40] rounded-[12px] w-[48%] lg:w-[25%]">
                <span className="text-xs text-[#91abc9]">Winrate</span>
                <h4 className="font-bold text-lg flex gap-1 items-center">
                  <img className="w-[20px]" src={iconWinrate} />
                  { (player?.total_win / player?.total_played * 100).toFixed(2) }
                </h4>
              </div>
              <div className="p-3 bg-[#271B40] rounded-[12px] w-[48%] lg:w-[25%]">
                <span className="text-xs text-[#91abc9]">Luckiest Win</span>
                <h4 className="font-bold text-lg flex gap-1 items-center">
                  <img className="w-[20px]" src={iconLucky} />
                    {player?.luckiest_win}x
                  </h4>
              </div>
            </div>
            <div className="flex flex-wrap gap-2 lg:flex-nowrap justify-between mt-2">
              <div className="p-3 bg-[#271B40] rounded-[12px] w-[48%] lg:w-[50%] hl">
                <span className="text-xs text-[#91abc9]">Total Points</span>
                <h4 className="font-bold text-lg flex gap-1 items-center">
                  <img className="w-[20px]" src={iconPlayed} />
                  {player?.total_point?.toLocaleString('en-US')}
                </h4>
              </div>
              <div className="p-3 bg-[#271B40] rounded-[12px] w-[48%] lg:w-[50%] hl">
                <span className="text-xs text-[#91abc9]">Biggest Win</span>
                <h4 className="font-bold text-lg flex gap-1 items-center">
                  <img className="w-[20px]" src={iconStar} />
                  {player?.biggest_win}
                </h4>
              </div>
            </div>
          </div>
          
        </div>
      </Modal>

    </div>
    
  );
};
export default Game;
