import { Modal } from "antd";
import { EyeFilled, HistoryOutlined, StepForwardOutlined, ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useWallet, useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";

import { AnchorProvider, setProvider } from "@coral-xyz/anchor";

import { useEffect, useState, useRef } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";

import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import Slider from "react-slick";

import logoSol from "../../assets/img/sol.svg";
import iconWin from "../../assets/img/icon-win.svg";
import iconWinrate from "../../assets/img/icon-winrate.svg";
import iconLucky from "../../assets/img/icon-lucky.svg";
import iconPlayed from "../../assets/img/icon-played.svg";
import iconStar from "../../assets/img/icon-star.svg";
import defaultUser from "../../assets/img/default_user.png";
import { truncateString, abbreviateNumber } from "../../ultils";
import * as helpers from "../../web3/helpers";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { round } from "lodash";

ChartJS.register(ArcElement, Tooltip);

const RoundDetail = () => {
  const navigate = useNavigate();
  const { roundIndex } = useParams();
  const sliderRef = useRef();
  
  const { connection } = useConnection();
  const wallet = useAnchorWallet();
  const provider = new AnchorProvider(connection, wallet, {});
  setProvider(provider);

  const { publicKey } = useWallet();

  const [chartData, setChartData] = useState();
  const [chartOptions, setChartOptions] = useState();
  
  const [players, setPlayers] = useState();
  const [player, setPlayer] = useState();

  const [rotate, setRotate] = useState(0);

  const [currentRoundInfo, setCurrentRoundInfo] = useState({
    claimed: false,
    deposits: [],
    drawnAt: 0,
    index: 0,
    startAt: 0,
    status: `open`,
    totalAmount: 0,
    winner: '',
    playerInfo: {}
  });

  useEffect(() => {
    csGetRound();
  }, [publicKey, roundIndex]);

  const csGetRound = async () => {
    const roundPublicKey = await helpers.getRoundPublicKey(roundIndex);
    const round = await helpers.getRound(roundIndex, roundPublicKey, publicKey);
    setData(round);
  }

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const handleCancelUserModal = () => {
    setIsUserModalOpen(false);
  };
  const handleOpenUserModal = async (userInfo) => {
    setPlayer(userInfo);
    setIsUserModalOpen(true);
  };

  const handleRoundOldest = () => {
    let roundIndex = 1;
    if(currentRoundInfo && currentRoundInfo.index > 2){
      roundIndex = currentRoundInfo.index - 1;
    }
    navigate(`/history/${roundIndex}`, { replace: false });
  }
  const handleRoundNewest = () => {
    if(currentRoundInfo && currentRoundInfo.config?.currentRound - 1 == parseInt(roundIndex)){
      console.log(currentRoundInfo.index, parseInt(roundIndex))
      navigate(`/game`, { replace: false });
    }else{
      let roundNext = parseInt(roundIndex) + 1;
      navigate(`/history/${roundNext}`, { replace: false });
    } 
  }

  const setData = async (round) => {
    let depositer = [];
    let currentPlayer = {
      amount: 0, winchance: 0
    };
    if(round){

      const apiRoundData = await helpers.apiRound(roundIndex);
      // console.log(`apiRoundData`,apiRoundData)
      if(apiRoundData && apiRoundData.players.length > 0){
        apiRoundData.players.map((item, index) => {
          depositer.push({
            wallet: item.wallet,
            winchance: (item.amount/apiRoundData.total_amount * 100).toFixed(2),
            amount: item.amount.toFixed(2),
            avatar: helpers.getAvatar(item.wallet),
            points: item.point,
            total_point: item.total_point,
            point_rate: item?.point_rate,
            color: helpers.getColor(index),
            is_won: item?.wallet == currentRoundInfo?.winner ? true : false,
            played: item.total_played,
            won: item.total_win,
            biggest_win: item.biggest_win,
            luckiest_win: item.luckiest_win
          })
          if(publicKey && item.wallet == publicKey.toBase58()){
            currentPlayer = {
              amount: item.amount,
              winchance: (item.amount/apiRoundData.total_amount * 100).toFixed(2)
            }
          }
        });
      }
    }

    
    depositer.sort((a, b) => (a.is_won < b.is_won ? 1 : -1))
    console.log(`depositer`, depositer)
    setPlayers(depositer);

    // setup winner
    let degMax = 0;
    let winnerObj = depositer.find(x => x.wallet === round.winner);
    let winnerIndex = depositer.indexOf(winnerObj);
    depositer.slice(0, winnerIndex+1).map((item) => {
        let currentDeg = 360*item.winchance/100;
        degMax += currentDeg;
    });
    let degMin = degMax - 360*depositer[winnerIndex]?.winchance/100;
    const rotateDeg = (degMax + degMin) / 2;
    const rotateValue = (360 - rotateDeg);
    setRotate(rotateValue);
    // sliderRef.current.slickGoTo(winnerIndex);

    const winnerData = {
      winnerPointRate: depositer[winnerIndex]?.point_rate,
      winnerWallet: depositer[winnerIndex]?.wallet
    }

    // console.log(depositer[winnerIndex])
    
    setCurrentRoundInfo({...round, currentPlayer, ...winnerData});

    const chartLabels = [];
    const chartNumber = [];
    const chartBg = [];
    depositer.map((item) => {
      chartLabels.push(truncateString(item.wallet, 4, 4));
      chartNumber.push(item.amount);
      chartBg.push(`#${item.color}`);
    });
    
    setChartData({
      labels: chartLabels,
      datasets: [
        {
          label: "",
          data: chartNumber,
          backgroundColor: chartBg,
          borderWidth: 0,
          hoverOffset: 5,
          cutout: '75%',
          rotation: 0,
          animations: false,
        }
      ],
    });

    setChartOptions(
      {
        plugins: {
          tooltip: {
            enabled: false,
            external: function(context) {
              // document.querySelectorAll(".player-item").forEach(el => el.classList.remove("hover"));
              // const tooltipModel = context.tooltip;
              // console.log(`tooltipModel`, tooltipModel);
              // const el = document.querySelectorAll(`.player-item[dataindex='${tooltipModel.dataPoints[0].dataIndex}']`);
              // console.log(`el`, el[0])
              // el[0].classList.add("hover");
            }
          }
        },
        onClick: (e) => {
          // console.log(e.chart.tooltip.dataPoints[0].dataIndex);
        }
      }
    )
  };

  const ref = useRef(null);

  const slickSettings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false
  };

  return (
    <div className="container-fluid mx-auto py-0 px-2 game">
      <div className="flex flex-col lg:grid grid-cols-12 grid-rows-6 lg:gap-2 h-full">

        <div className="grid-box  round-info col-span-3 row-span-3 row-start-1 col-start-1">
          <div className="spin-box h-full">
            <div className="spin-box-head flex justify-between lg:mb-3 items-center hidden lg:flex">
              <NavLink to="/history" className="flex items-center gap-2 px-3 text-sm py-[8px] rounded-[12px] border-[#735893] border uppercase">
                <HistoryOutlined /> History
              </NavLink>
              <div className="slick-control flex gap-1">
                {
                  currentRoundInfo && currentRoundInfo.index > 0 ?
                  <button className="button is-outline" onClick={() => { handleRoundOldest() }} ><ArrowLeftOutlined /></button>
                  :
                  <button className="button is-outline disabled opacity-50"><ArrowLeftOutlined /></button>
                }
                <button className="button is-outline" onClick={() => { handleRoundNewest() }} ><ArrowRightOutlined /></button>
                <NavLink to="/game" className="button is-outline" ><StepForwardOutlined /></NavLink>
              </div>
            </div>

            <div className="countdown !hidden lg:!flex">
              <button className="flex items-center justify-center gap-2">00:00</button>
            </div>

            <div className="lg:mt-2">
              <div className="grid grid-cols-3 lg:grid-cols-2 text-[#ffffff80] ">
                <div className="block text-sm p-2 hidden lg:block text-center">
                  <p className="text-xs mb-1">Round</p>
                  <div className="flex items-center justify-center gap-2 text-xl font-bold lg:mb-1 text-[#ffffff]">
                    {roundIndex}
                  </div>
                </div>

                <div className="block text-sm hidden lg:block p-2 text-center">
                  <p className="text-xs mb-1">Watching</p>
                  <div className="flex items-center justify-center gap-2 text-xl font-bold lg:mb-1 text-[#FDDD34]">
                    <EyeFilled /> -
                  </div>
                </div>

                <div className="block text-sm p-2 hidden lg:block text-center border-t-[1px] border-[#ffffff1f]">
                  <p className="text-xs mb-1">Players</p>
                  <div className="flex items-center justify-center text-xl font-bold lg:mb-1">
                    <span className="text-[#ffffff]">{players?.length}</span>/10K
                  </div>
                </div>

                <div className="block text-sm p-2 text-center lg:border-t-[1px] lg:border-[#ffffff1f]">
                  <p className="text-xs mb-1">Prize Pool</p>
                  <div className="flex items-center justify-center gap-2 text-xl font-bold lg:mb-1 text-[#ffffff]">
                    <img  className="w-[24px] h-[24px]" src={logoSol} />
                    {currentRoundInfo.totalAmount.toLocaleString('en-US')}
                  </div>
                </div>

                <div className="block text-sm p-2 lg:pt-1 text-center">
                  <p className="text-xs mb-1">Your Win Chance</p>
                  <div className="flex items-center justify-center gap-2 text-xl font-bold lg:mb-1">
                    { currentRoundInfo.currentPlayer?.winchance?.toLocaleString('en-US')}%
                  </div>
                </div>

                <div className="block text-sm p-2 lg:pt-1 text-center">
                <p className="text-xs mb-1">Your entries</p>
                  <div className="flex items-center justify-center gap-2 text-xl font-bold lg:mb-1">
                    <img  className="w-[24px] h-[24px]" src={logoSol} />
                    { currentRoundInfo.currentPlayer?.amount.toLocaleString('en-US')}
                  </div>
                </div>

                <div className="block text-sm p-2 hidden lg:block text-center border-t-[1px] border-[#ffffff1f]">
                  <p className="text-xs mb-1">Your Future Entries</p>
                  <div className="flex items-center justify-center text-xl font-bold lg:mb-1">
                    -
                  </div>
                </div>

                <div className="block text-sm p-2 hidden lg:block text-center border-t-[1px] border-[#ffffff1f]">
                  <p className="text-xs mb-1">Total (0 Avg)</p>
                  <div className="flex items-center justify-center gap-2 text-xl font-bold lg:mb-1 text-[#ffffff]">
                    <img  className="w-[24px] h-[24px]" src={logoSol} />
                    -
                  </div>
                </div>
              </div>
              
            </div>
            <NavLink to="/game"
                  className="button bg-primary-gradient w-full has-icon lg:!hidden"
              >
                  Back to Current Round
              </NavLink>
          </div>
        </div>

        <div className="grid-box col-span-3 row-span-3 row-start-4 col-start-1 hidden lg:block">
          <div className="spin-box h-full relative">
            <NavLink to="/game"
                className="button bg-primary-gradient w-full has-icon"
            >
                Back to Current Round
            </NavLink>
          </div>
        </div>

        <div className="grid-box box-players col-span-9 row-span-2 col-start-4 row-start-5">
          <div className="spin-box h-full relative">
            <div className="spin-box-head flex justify-between mb-2 items-center">
              <h3 className="font-bold text-lg">Round #{currentRoundInfo.index} - {players?.length} Players</h3>
              <div className="slick-control flex gap-1">
                <button className="button is-outline"
                  onClick={() => sliderRef.current.slickPrev()}
                ><ArrowLeftOutlined /></button>
                <button className="button is-outline"
                  onClick={() => sliderRef.current.slickNext()}
                ><ArrowRightOutlined /></button>
                <button className="button is-outline"
                  onClick={() => sliderRef.current.slickGoTo(players.length)}
                ><StepForwardOutlined /></button>
              </div>
            </div>
            <Slider ref={sliderRef} className="players-list" {...slickSettings}>
              {
                players &&
                  players.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => handleOpenUserModal(item)}
                        data-index={index}
                        className={`player-item flex flex-col items-center justify-center gap-3 ${item.wallet == currentRoundInfo?.winner && 'win'}`}
                      >
                        <div className={`color color-${item.color}`}></div>
                        <img className="w-[46px] h-[46px] m-auto mb-2 rounded-full bg-[#001529]" src={item.avatar}/>
                        <div className="wallet mb-2">
                          <div className="font-bold">
                            {truncateString(item.wallet, 4, 4)}
                          </div>
                          <span className="text-xs text-[#ffffff80]">{abbreviateNumber(item.points)} PTS</span>
                        </div>
                        <div className="amount text-right">
                          <div className="winchance mb-2 font-bold">{item.winchance}%</div>
                          <div className="flex items-center justify-center gap-1">
                            {item.amount}
                            <img  className="w-[18px] h-[18px]" src={logoSol} />
                          </div>
                        </div>
                      </div>
                    )
                  })
              }
            </Slider>
            <div id="chartjs-tooltip"></div>
          </div>
        </div>
        <div className="grid-box box-chart col-span-9 row-span-4 col-start-4 row-start-1 order-first lg:order-none">
          <div className="spin-box-head flex justify-between lg:mb-3 items-center lg:hidden  relative z-[99]">
            <NavLink to="/history" className="flex items-center gap-2 px-3 text-sm py-[8px] rounded-[12px] border-[#735893] border uppercase">
              <HistoryOutlined /> History
            </NavLink>
            <div className="slick-control flex gap-1">
              {
                currentRoundInfo && currentRoundInfo.index > 0 ?
                <button className="button is-outline" onClick={() => { handleRoundOldest() }} ><ArrowLeftOutlined /></button>
                :
                <button className="button is-outline disabled opacity-50"><ArrowLeftOutlined /></button>
              }
              <button className="button is-outline" onClick={() => { handleRoundNewest() }} ><ArrowRightOutlined /></button>
              <NavLink to="/game" className="button is-outline" ><StepForwardOutlined /></NavLink>
            </div>
          </div>
          <div className="spin-box spin-box-main h-full relative">
            
            <div className="spinner-wrp">
              <div className="spinner">
                <div className="spinner-inner">
                  {
                    chartData && 
                      <div className="spinwrp">
                        <div className="spincontent-wrp"></div>
                        <div className="spincontent">
                          {
                            currentRoundInfo.status == 'drawn' && currentRoundInfo.winner != "" &&
                            <div className="inner winner text-center ">
                                <div className="win-wrp">
                                  <img src={currentRoundInfo ? helpers.getAvatar(currentRoundInfo?.winner) : defaultUser} />
                                </div>
                                <div className="font-bold text-primary-gradient text-[18px] lg:text-[24px] mb-1">{truncateString(currentRoundInfo?.winner, 4, 4)}</div>
                                <div className="flex items-center justify-center text-[24px] lg:text-[48px] font-bold gap-1 lg:gap-2 leading-6">
                                    <img className="w-[24px] lg:w-[40px] h-[24px] lg:h-[40px]" src={logoSol} />
                                    <span>{currentRoundInfo.totalAmount.toLocaleString("en-US")}</span>
                                </div>
                                <div className="flex items-center justify-center gap-2">
                                  <div className="flex items-center gap-1">
                                    <span>{currentRoundInfo.winnerPointRate}x WIN</span>
                                  </div>
                                </div>
                            </div>
                          }
                          { 
                            currentRoundInfo.status == 'drawn' && currentRoundInfo.winner == "" &&
                            <div className="inner cancelled">
                              <div className="flex items-center justify-center text-[32px] lg:text-[48px] font-bold gap-1 lg:gap-2">
                                <img className="w-[32px] lg:w-[40px] h-[32px] lg:h-[40px]" src={logoSol} />
                                <span>{(currentRoundInfo?.totalAmount).toLocaleString('en-US')}</span>
                              </div>
                              <div className="text-center font-bold text-[18px] lg:text-[24px] mb-1 text-primary-gradient">Not Enough Players</div>
                            </div>
                          }
                          
                        </div>
                        <div className="spinchart" style={{
                          transform: `rotate(${rotate}deg)`
                        }}>
                          <Doughnut data={chartData} options={chartOptions} />
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  

      <Modal
        forceRender
        open={isUserModalOpen}
        onCancel={handleCancelUserModal}
        footer={false}
        className="modal-user max-w-[560px]"
        style={{
          backgroundImage: `url(${player && helpers.getAvatar(player.wallet)})`
        }}
      >
        <div className="text-white">
          <div className="profile text-center mt-5">
            <img className="w-[70px] h-[70px] m-auto rounded-full bg-[#001529]" src={player && helpers.getAvatar(player.wallet)}/>
            <div className="font-bold  text-[24px] mb-1 mt-3">{player && truncateString(player?.wallet, 4, 4)}</div>
          </div>
          <div className="user-past-rounds mt-5">
            <div className="flex items-center justify-between mb-5">
              <h3 className="font-bold mb-2">Player Statistic</h3>
              <NavLink target="_blank" to={`https://explorer.solana.com/address/${player?.wallet}?cluster=${process.env.REACT_APP_NETWORK}`} className="flex items-center gap-2 text-[#fff] font-bold" >
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_62_4918" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
                <rect width="22" height="22" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_62_4918)">
                <path d="M4.95005 18.7C4.4963 18.7 4.10786 18.5385 3.78474 18.2154C3.46161 17.8922 3.30005 17.5038 3.30005 17.05V4.95005C3.30005 4.4963 3.46161 4.10786 3.78474 3.78474C4.10786 3.46161 4.4963 3.30005 4.95005 3.30005H11V4.95005H4.95005V17.05H17.05V11H18.7V17.05C18.7 17.5038 18.5385 17.8922 18.2154 18.2154C17.8922 18.5385 17.5038 18.7 17.05 18.7H4.95005ZM8.8688 14.3L7.70005 13.1313L15.8813 4.95005H13.2V3.30005H18.7V8.80005H17.05V6.1188L8.8688 14.3Z" fill="white"/>
                </g>
                </svg>
                WALLET</NavLink>
            </div>

            <div className="flex flex-wrap gap-2 lg:flex-nowrap justify-between mt-2">
              <div className="p-3 bg-[#271B40] rounded-[12px] w-[48%] lg:w-[25%]">
                <span className="text-xs text-[#91abc9]">Wins</span>
                <h4 className="font-bold text-lg flex gap-1 items-center">
                  <img className="w-[20px]" src={iconWin} />
                  {player?.won}
                </h4>
              </div>
              <div className="p-3 bg-[#271B40] rounded-[12px] w-[48%] lg:w-[25%]">
                <span className="text-xs text-[#91abc9]">Total Played</span>
                <h4 className="font-bold text-lg flex gap-1 items-center">
                  <img className="w-[20px]" src={iconPlayed} />
                  {player?.played}
                </h4>
              </div>
              
              <div className="p-3 bg-[#271B40] rounded-[12px] w-[48%] lg:w-[25%]">
                <span className="text-xs text-[#91abc9]">Winrate</span>
                <h4 className="font-bold text-lg flex gap-1 items-center">
                  <img className="w-[20px]" src={iconWinrate} />
                  {(player?.won / player?.played * 100).toFixed(2)}
                </h4>
              </div>
              <div className="p-3 bg-[#271B40] rounded-[12px] w-[48%] lg:w-[25%]">
                <span className="text-xs text-[#91abc9]">Luckiest Win</span>
                <h4 className="font-bold text-lg flex gap-1 items-center">
                  <img className="w-[20px]" src={iconLucky} />
                    {player?.luckiest_win}x
                  </h4>
              </div>
            </div>
            <div className="flex flex-wrap gap-2 lg:flex-nowrap justify-between mt-2">
              <div className="p-3 bg-[#271B40] rounded-[12px] w-[48%] lg:w-[50%] hl">
                <span className="text-xs text-[#91abc9]">Total Points</span>
                <h4 className="font-bold text-lg flex gap-1 items-center">
                  <img className="w-[20px]" src={iconPlayed} />
                  {player?.total_point?.toLocaleString('en-US')}
                </h4>
              </div>
              <div className="p-3 bg-[#271B40] rounded-[12px] w-[48%] lg:w-[50%] hl">
                <span className="text-xs text-[#91abc9]">Biggest Win</span>
                <h4 className="font-bold text-lg flex gap-1 items-center">
                  <img className="w-[20px]" src={iconStar} />
                  {player?.biggest_win}
                </h4>
              </div>
            </div>
          </div>
          
        </div>
      </Modal>

    </div>
    
  );
};
export default RoundDetail;
